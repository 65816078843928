import React from 'react'
import './Plans.css'
import {plansData} from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'
import whatsapp2 from '../../assets/whatsapp'
import Modal from '../Modal/Modal'

const Plans = () => {
  return (
    <>
    <div id='plans' className='div0'></div>
    <div className="plans-container">
        <div className="blur plans-blur1"></div>
        <div className="blur plans-blur2"></div>
        <div className="plans-header">
            <span className='title-p'>PLANES</span>
        </div>
        {/* Plans */}
        <div className="plans">
            {plansData.map((plan, i) => (
                <div className="plan" key={i}>
                    {plan.icon}
                    <span>{plan.name}</span>
                    <span>$ {plan.price}</span>
                    <div className="features">
                        {plan.features.map((feature, i) => (
                        <div className="feature">
                            <img src={whiteTick} alt=""/>
                            <span key={i}>{feature}</span>
                        </div>
                    ))}
                    </div>
                    <Modal img={plan.img}/>
                </div>
            ))}
        </div>
        <div className='legal'>
            <p>*FORMAS DE PAGO: Efectivo, Transferencia bancaria o Mercado Pago.
                Consulta por PROMOCIONES ESPECIALES a nuestro WhatsApp</p>
            <a href="https://wa.me/+59898057404" target="_blank" rel="noreferrer"><img src={whatsapp2} className='img2' alt="" /></a>
        </div>
    </div>
    </>
  )
}



export default Plans