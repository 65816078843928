import './App.css';
import AboutUs from './components/AboutUs/AboutUs';
import Footer from './components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Join from './components/Join/Join';
import Plans from './components/Plans/Plans';
import Programs from './components/Programs/Programs';
import Reasons from './components/Reasons/Reasons';
import Testimonials from './components/Testimonials/Testimonials';
import { Helmet } from "react-helmet-async";

function App() {
  return (
    <>
      <Helmet>
        <title>Plenitud Fit</title>
        <meta
          name="description"
          content="Divertite, se quien quieras ser y se parte de la mejor comunidad para entrenar"
        />
        <link rel="canonical" href="https://plenitudfit.com.uy/" />
      </Helmet>
      <div className="App">
        <Hero />
        <AboutUs />
        <Programs />
        <Plans />
        <Reasons />
        <Testimonials />
        <Join />
        <Footer />
      </div>
    </>
  );
}

export default App;
