import React, { useState } from "react";
import "./Modal.css";

export default function Modal( {img} ) {
  const [modal, setModal] = useState(false);
  console.log(img)

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
    document.body.classList.add("disable-animations");
  } else {
    document.body.classList.remove("active-modal");
    document.body.classList.remove("disable-animations");
  }

  return (
    <>
      <button onClick={toggleModal} className="btn btn-p">
        Empezar Ahora!
      </button>

      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <h2 className="txt-m">Escanea tu Codigo!</h2>
            <img src={img} className="img-m" alt="" />
            <button className="close-modal" onClick={toggleModal}>
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
}
