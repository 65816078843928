import './Join.css'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { faSquareFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faMap } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Join = () => {

    const notify = () => {
        toast.success(`Su mensaje fue enviado con exito`);
      };

    useEffect(() => {
        document.querySelectorAll('.writing :is(input , textarea)').forEach(writinginput => {
          writinginput.addEventListener('input', () => {
            if (writinginput.value.trim() !== '') {
              writinginput.classList.add('has-text');
            } else {
              writinginput.classList.remove('has-text');
            }
          });
        });
      }, []);
    
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_c4n7pgp', 'template_kt44u32', form.current, 'S4K5wYeKRN8XQJ9ZM')
        .then((result) => {
            notify()
            form.current.reset();
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    }
  return (
    <>
    <div className="join" id="join-us">
    {
    //#region Left
    } 
        <div className="left-j">
            <hr />
            <div>
                <span className='title-j'>¿Listo para comenzar?</span>
            </div>
            <div>
                <span className='title-j'>CONTACTANOS!</span>
            </div>
            <div className="social-links">
                <a href="https://www.instagram.com/plenitudfit/" target='blank'><FontAwesomeIcon  className="img-j" icon={faInstagram} style={{color: "#ffffff",}}/></a>
                <a href="mailto:plenitudfit@gmail.com" target='blank'><FontAwesomeIcon className="img-j" icon={faEnvelope} style={{color: "#ffffff",}} /></a>
                <a href="https://www.facebook.com/plenitudfitEF/" target='blank'><FontAwesomeIcon className='img-j' icon={faSquareFacebook} style={{color: "#ffffff",}} /></a>
                <a href="https://wa.me/+59898057404" target='blank'><FontAwesomeIcon className='img-j' icon={faWhatsapp} style={{color: "#ffffff",}} /></a>
                <a href="https://www.google.com/maps/place/Plenitud+Fit+Entrenamiento+%26+Fitness/@-34.8732795,-56.137731,15z/data=!4m6!3m5!1s0x959f813c34d8ec93:0x1bffef7637ab1305!8m2!3d-34.8732795!4d-56.137731!16s%2Fg%2F11fjzbt6w_?entry=ttu" target='blank'><FontAwesomeIcon className='img-j' icon={faMap} style={{color: "#ffffff",}} /></a>
            </div>
        </div>
    {
    //#endregion Left
    }
    {
    //#region Right
    } 
        <div className="right-j">
            <form ref={form} className='email-container' onSubmit={sendEmail}>
                <div className='title-start'>
                    <p>QUIERO EMPEZAR!</p>
                </div>
                <div className='entryarea writing input1-margintop'>
                    <input type="text" name="user_name" />
                    <label className='labelline'>Nombre</label>
                </div>
                <div className='entryarea writing'>
                    <input type="email" name="user_email" />
                    <label className='labelline'>Email</label>
                </div>
                <div className='entryarea writing'>
                    <textarea name="message" className='text-j' />
                    <label className='labelline-message'>Mensaje</label>
                </div>
                <input className='btn btn-j' type="submit" value="ENVIAR" />
            </form>
        </div>
    {
    //#endregion Right
    }
    </div>
    <ToastContainer />
    </>
  )
}

export default Join