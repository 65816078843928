import React from 'react'
import './Testimonials.css'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

const Testimonials = () => {
  return (
    <>
    <div className="testimonials">
            <div>
                <p className='test-text'>¿QUÉ DICE LA GENTE DE PLENITUD FIT?</p>
            </div>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#161718',
                    '--swiper-pagination-color': '#161718',
                }}
                spaceBetween={30}
                lazy={true}
                autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                  }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper3"
                >
                <SwiperSlide>
                    <span className='name'>KARINA LANDEIRA</span>
                    <div className='stars'>
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                    </div>
                    <span className='test-parr1'>Muy buena onda y ahora con el bar de proteína que se hizo más aún!</span>
                    <span className='test-parr2'>Super recomiendo! Ideal para irte ya con una merienda puesta,</span>
                    <span className='test-parr3'>un desayuno post entrenamiento o una cena liviana si se entrena de noche. Espectacular.</span>
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
                <SwiperSlide>
                <span className='name'>María Eugenia Pastor</span>
                    <div className='stars'>
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                    </div>
                    <span className='test-parr1'>Excelente clima e instructores. El lugar muy bien equipado, los profesores son muy </span>
                    <span className='test-parr2'>profesionales y las clases son muy motivantes y divertidas.</span>
                    <span className='test-parr3'>Te hacen sentir como en tu casa, muy recomendable!</span>
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
                <SwiperSlide>
                <span className='name'>Valentina Mernies</span>
                    <div className='stars'>
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                    </div>
                    <span className='test-parr1'>El único lugar donde encontré extrema comodidad para entrenar,</span>
                    <span className='test-parr2'>los mejores profes por lejos, siempre cuidándonos y motivando a mejorar! Graciassss</span>
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
                <SwiperSlide>
                <span className='name'>Nicolás Gaggioni</span>
                    <div className='stars'>
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                    </div>
                    <span className='test-parr1'>Divino Gym! Muy bueno todo, los profes, las instalaciones!</span>
                    <span className='test-parr2'>Siempre con alegria y muy buena onda para mejorar! 🙌</span>
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
                <SwiperSlide>
                <span className='name'>Andres Vonder</span>
                    <div className='stars'>
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                    </div>
                    <span className='test-parr1'>¿Buscas un lugar para entrenar? No lo busques más, acá encontrarás todo lo que necesitas.</span>
                    <span className='test-parr2'>Buenas instalaciones, gran equipo de profesionales, que te brindan lo mejor para un excelente </span>
                    <span className='test-parr3'>entrenamiento. El ambiente y la buena onda que se genera en cada clase es genial. Los recomiendo 100%.</span>
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
            </Swiper>
            <a href="https://www.google.com/search?q=plenitud+fit&sca_esv=572350337&sxsrf=AM9HkKlTaCxY0s8HkhO7A8-Fkf1CTDSfBQ%3A1696975016443&ei=qMglZfbXGqbT1sQP67m8-A4&ved=0ahUKEwj2y7DIvOyBAxWmqZUCHescD-8Q4dUDCBA&oq=plenitud+fit&gs_lp=Egxnd3Mtd2l6LXNlcnAiDHBsZW5pdHVkIGZpdDIEECMYJzIHECMYigUYJzILEC4YgAQYxwEYrwEyBRAAGIAEMgYQABgWGB4yBhAAGBYYHjIGEAAYFhgeMgYQABgWGB4yBhAAGBYYHkjPGVDAA1jTBHABeAGQAQCYAaABoAG_AqoBAzAuMrgBDMgBAPgBAcICChAAGEcY1gQYsAPiAwQYACBBiAYBkAYI&sclient=gws-wiz-serp#lrd=0x959f813c34d8ec93:0x1bffef7637ab1305,1,,,," target='blank' className='test-link'>VER MÁS RESEÑAS AQUI</a>
    </div>
    </>
  )
}

export default Testimonials