import './Hero.css'
import Header from '../Header/Header'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import plenitud1 from '../../assets/Plenitud1.jpeg';
import plenitud2 from '../../assets/Plenitud2.jpeg';
import plenitud3 from '../../assets/Plenitud3.jpg';
import plenitud4 from '../../assets/Plenitud4.jpg';
import plenitud5 from '../../assets/Plenitud5.jpeg';


const Hero = () => {
  return (
    <>
    <Header />
    <div className="hero" id='home'>
      <div className=""></div>
{
//#region Left
}
        <div className="left-h">
            <Swiper
            spaceBetween={30}
            lazy={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
            >
              <SwiperSlide>
                <span className='hero-text'>En este gimnasio nos divertimos mientras entrenamos</span>
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              </SwiperSlide>
              <SwiperSlide>
                <span className='hero-text'>Somos libres de estereotipos, aquí sos quien quieras ser</span>
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              </SwiperSlide>
              <SwiperSlide>
                <span className='hero-text'>Te invitamos a conocer a esta gran comunidad</span>
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              </SwiperSlide>
        </Swiper>
        </div>
{
//#endregion Left
}

{
//#region Right
}
        <div className="right-h">
        <Swiper
            style={{
              '--swiper-navigation-color': '#fff',
              '--swiper-pagination-color': '#fff',
            }}
            spaceBetween={30}
            lazy={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper2"
        >
            <SwiperSlide>
              <img
                src={plenitud1}
                loading="lazy"
                alt=''
              />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={plenitud2}
                loading="lazy"
                alt=''
              />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={plenitud3}
                loading="lazy"
                alt=''
              />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={plenitud4}
                loading="lazy"
                alt=''
              />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={plenitud5}
                loading="lazy"
                alt=''
              />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </SwiperSlide>
        </Swiper>
          
        </div>
{
//#endregion Right
}
    </div>
    </>
  )
}

export default Hero