import './AboutUs.css'
// Import Swiper React components
// eslint-disable-next-line no-unused-vars
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
// eslint-disable-next-line no-unused-vars
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import video from '../../assets/VideoPlenitud.mp4'


const AboutUs = () => {
  // eslint-disable-next-line no-unused-vars
  const mobile = window.innerWidth <= 420 ? true: false
  return (
    <>
      <p id="aboutus"></p>
      <div className="aboutus">
        <div className="container">
          {
            //#region Left
          }
          <div className="left-a">
            <p className="title-aboutus">NOSOTROS</p>
            <p className="subtitle-aboutus1">
              <strong>MISÓN: </strong>
              Somos un equipo especializado en entrenamiento deportivo que
              promueve la vida saludable donde nuestra principal característica
              es adecuarnos a las necesidades de la persona con una visión
              holística, abarcando todas las edades y condiciones psicofísicas.
            </p>
            <p className="subtitle-aboutus2">
              <strong>VISIÓN: </strong>Ser un equipo de referencia para el
              entrenamiento, preparación deportiva y vida saludable.
            </p>
            <p className="subtitle-aboutus2">
              <strong>VALORES: </strong>Promovemos
              el trabajo equipo, fomentamos la diversión en el entrenamiento y
              brindamos un servicio de calidad. Nos ocupamos por la continua
              formación y actualización de nuestros conocimientos ¡Pertenecé a
              la mejor comunidad!
            </p>
          </div>
          {
            //#endregion Left
          }
          {
            //#region Right
          }
          <div className="right-a">
            <video
              src={video}
              autoplay="autoplay"
              loop="loop"
              controls="controls"
              muted="muted"
              controlsList="nodownload"
              className="video-a"
            ></video>
          </div>
          {
            //#endregion Right
          }{" "}
        </div>
      </div>
    </>
  );
}

export default AboutUs