import React from 'react'
import './Programs.css'
import horarios from '../../assets/HorariosB.png';

const Programs = () => {
  return (
    <div className="programs" id="progr">
          {/* Header */}
          <div className='programs-header'>
            <span className='title'>ACTIVIDADES Y HORARIOS</span>
          </div>

          <div className="program-categories">
           <img src={horarios} className="img-program" alt="" />
          </div>
    </div>
  )
}

export default Programs