import React, { useState, useEffect } from "react";
import "./Header.css";
import Logo from "../../assets/logo.png";
import { Link } from "react-scroll";
import whatsapp from "../../assets/whatsapp";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = () => {
  // to change burger classes
  const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
  const [menu_class, setMenuClass] = useState("menu visible");
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 768) {
        setMenuClass("menu hidden");
      } else {
        // You can adjust this value based on your requirements
        setMenuClass("menu visible");
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial setup on component mount
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // toggle burger menu change
  const updateMenu = () => {
    if (!isMenuClicked) {
      setBurgerClass("burger-bar clicked");
      setMenuClass("menu visible");
    } else {
      setBurgerClass("burger-bar unclicked");
      setMenuClass("menu hidden");
    }
    setIsMenuClicked(!isMenuClicked);
  };

  return (
    <div className="header">
      <img className="logo" src={Logo} alt="" />
      <div className="navigation">
        <nav className="hidden">
          <div className="burger-menu" onClick={updateMenu}>
            <div className={burger_class}></div>
            <div className={burger_class}></div>
            <div className={burger_class}></div>
          </div>
        </nav>

        <ul className={menu_class}>
          <li>
            <Link to="aboutus" span={true} smooth={true} className="first-item">
              NOSOTROS
            </Link>
          </li>
          <li>
            <Link to="programs" span={true} smooth={true}>
              ACTIVIDADES Y HORARIOS
            </Link>
          </li>
          <li>
            <Link to="plans" span={true} smooth={true}>
              PLANES
            </Link>
          </li>
          <li>
            <Link to="reasons" span={true} smooth={true}>
              BENEFICIOS
            </Link>
          </li>
          <li>
            <Link to="testimonials" span={true} smooth={true}>
              CONTACTO
            </Link>
          </li>
          <li>
            <a
              href="https://web.smartgym.uy/app/"
              target="blank"
              title="Acceso a usuarios"
            >
              <FontAwesomeIcon icon={faUser} />
            </a>
          </li>
          <li>
            <a href="https://wa.me/+59898057404" target="blank">
              <img src={whatsapp} className="img" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
