import React from 'react'
import './Reasons.css'
import tick from '../../assets/tick.png'
import plenitud6 from '../../assets/plenitud6.jpeg'

const Reasons = () => {
  return (
    <>
    <div className="reasons">
    {
    //#region Left
    }    
        <div className="left-r">
            <div className='box'>
               <img src={plenitud6} className='img6' alt="" />
            </div>
        </div>
    {
    //#endregion Left
    }
    {
    //#region Right
    }
        <div className="right-r">
            <div className='div-title'>
                <span className='title-benefits' id="reasons">Beneficios para socios</span>
                <span className='title-benefits'> Pase Libre:</span>
            </div>
            <div className='details-r'>
                <div>
                    <img src={tick} alt="" />
                    <span className='subtitle-benefits'>Óptica Cooptica: 15% OFF en cristales, armazones y lentes de sol</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span className='subtitle-benefits'>Kinesiología Deportiva: 15% OFF en colocación de Tapping y masajes terapéuticos</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span className='subtitle-benefits'>Fisioterapia: 25% OFF en rehabilitación de lesiones deportivas, pre - post operatorio</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span className='subtitle-benefits'>Nutrición: 25% OFF en servicios de nutrición, antropometría y más</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span className='subtitle-benefits'>Servicio de Masajes: 15% OFF (Descontracturantes, remodeladores, lipoescultura manual y más)</span>
                </div>
                <div>
                    <img src={tick} alt=""/>
                    <span className='subtitle-benefits last-parr'>Depilación Definitiva: 15% OFF método láser SOPRANO</span>
                </div>
            </div>
        </div>
    {
    //#endregion Right
    }
    </div>
    </>
  )
}

export default Reasons