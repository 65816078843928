import React from 'react'
import './Footer.css'
import logo from '../../assets/logo.png'
import { faSquareFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faMap } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <div className="footer-container">
        <hr />
        <div className="footer">
            <div className="social-links">
                <a href="https://www.instagram.com/plenitudfit/" target='blank'><FontAwesomeIcon  className="img-i" icon={faInstagram} style={{color: "#ffffff",}}/></a>
                <a href="mailto:plenitudfit@gmail.com" target='blank'><FontAwesomeIcon className="img-i" icon={faEnvelope} style={{color: "#ffffff",}} /></a>
                <a href="https://www.facebook.com/plenitudfitEF/" target='blank'><FontAwesomeIcon className='img-i' icon={faSquareFacebook} style={{color: "#ffffff",}} /></a>
                <a href="https://wa.me/+59898057404" target='blank'><FontAwesomeIcon className='img-i' icon={faWhatsapp} style={{color: "#ffffff",}} /></a>
                <a href="https://www.google.com/maps/place/Plenitud+Fit+Entrenamiento+%26+Fitness/@-34.8732795,-56.137731,15z/data=!4m6!3m5!1s0x959f813c34d8ec93:0x1bffef7637ab1305!8m2!3d-34.8732795!4d-56.137731!16s%2Fg%2F11fjzbt6w_?entry=ttu" target='blank'><FontAwesomeIcon className='img-i' icon={faMap} style={{color: "#ffffff",}} /></a>
            </div>
            <div className="logo-f">
                <img src={logo} alt="" />
            </div>
            <div className='legal-container'>
              <p className='footer-legal'>© 2023 Plenitud Fit®. Marca registrada. Todos los derechos reservados. La información es propiedad de Plenitud Fit®. Prohibido recolectar, reproducir y comercializar.</p>
              <a href="https://wa.me/+59894299470" target="blank" className='footer-legal last-p'>Designed by VPEX - Infrastructure & Development</a>
            </div>
        </div>
    </div>
  );
};

export default Footer